import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import GalleryComponent from "../components/image-gallery"

const Projects = (props) => {
    const projectsData = props.data.allContentfulProjects.edges
    return (
        <Layout>
            <Seo
                title="Sell Your Home Fast for Cash: Our Recent Projects"
                description="Explore our Recent Projects page to see how Dahlia Homes helps homeowners sell their houses fast for cash. Specializing in quick home purchases, we provide fair cash offers and 
                hassle-free transactions, buying homes in any condition without the need for repairs or showings. Whether facing foreclosure, dealing with inherited property, or needing to move quickly, our streamlined 
                process ensures you get cash in hand in as little as 7 days. Browse through our success stories, complete with before and after images, and discover why so many choose us for their quick home sale needs. 
                Contact us today for a no-obligation cash offer and experience the ease and speed of selling your home fast for cash."
                keywords={["sell your sacramento house", "sacramento homeowners", "get an offer on your sacramento house"]}
                location={props.location.pathname}
            />
            <div className="bg-dhgray py-16">
                <div className="container mx-auto">
                    <h1 className="font-bold text-2xl lg:text-3xl text-center">Dahlia Homes Projects</h1>
                    <div className="grid grid-cols-2 md:grid-cols-3 px-5 gap-x-5 xl:grid-cols-5">
                        {projectsData.map((project, index) => (
                            <GalleryComponent key={index} project={project.node} />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Projects
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulProjects {
        edges {
          node {
            title {
                title
            }
            images {
              title
              description
              gatsbyImageData
              url
            }
          }
        }
      }
}
`